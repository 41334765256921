// Vertical Menu
window.openMenu = function openMenu() {
  let x = document.getElementById("vertical-menu");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
};

window.closeMenu = function closeMenu() {
  let x = document.getElementById("vertical-menu");
  x.style.display = "none";
};

/* Language Picker */
document.addEventListener("DOMContentLoaded", function () {
  var langSelectors = document.querySelectorAll(".js-lang-selector");
  var lang = document.documentElement.lang;
  var path = window.location.pathname;
  var parts = path.split("/");
  langSelectors.forEach((langSelector) => {
    langSelector.value = lang;
    langSelector.addEventListener("change", function () {
      var selected = langSelector.value;
      var newPath;
      if (parts[parts.length - 1].length < 4) {
        newPath = selected === "en" ? "/" : "/" + selected;
        window.location.href = newPath;
        return;
      }
      newPath =
        selected === "en"
          ? "/" + parts[parts.length - 1]
          : "/" + selected + "/" + parts[parts.length - 1];
      window.location.href = newPath;
      return;
    });
  });
});
